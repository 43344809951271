<template>
    <div class="is-loading d-flex justify-content-center align-items-center">
        <img width="50px" class="animate-flicker" :src="loading_gif"  alt="Loading...">
    </div>
</template>


<script>
import loading_gif from "../assets/images/logo.png";
export default {
    name: "BaseLoading",
    data() {
      return {
        loading_gif: loading_gif
      }
    }
}
</script>
<style lang="scss">
@keyframes flickerAnimation {
  0%   { 
    opacity:0.2;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
@-o-keyframes flickerAnimation{
  0%   { 
    opacity:.4;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
@-moz-keyframes flickerAnimation{
  0%   { 
    opacity:0.2;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
@-webkit-keyframes flickerAnimation{
  0%   { 
    opacity:0.2;
    transform: scale(0.2)
  }
  
  100% { 
    opacity:1;
    transform: scale(1.2);  
  }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}
</style>