<template>
  <!-- Main sidebar -->
  <div
    class="sidebar sidebar-dark sidebar-main sidebar-fixed sidebar-expand-md"
  >
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a @click="_toggleSidebarMobile" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      Navigation
      <a @click="_toggleSidebarMobile" class="sidebar-mobile-main-toggle">
        <i class="icon-x"></i>
      </a>
    </div>
    <!-- /sidebar mobile toggler -->

    <!-- Sidebar content -->
    <div class="sidebar-content pb-4">
      <!-- User menu -->
      <div class="sidebar-user">
        <div class="card-body">
          <div class="media">
            <div class="mr-3">
              <router-link :to="{ name: 'Profile' }">
                <img
                  src="../assets/global_assets/images/image.png"
                  width="38"
                  height="38"
                  class="rounded-circle"
                  alt
                />
              </router-link>
            </div>

            <div class="media-body">
              <div class="media-title font-weight-semibold">
                {{ user.first_name || "" }} {{ user.last_name || "" }}
              </div>
              <div class="font-size-xs opacity-50">
                <i class="icon-pin font-size-sm"></i>
                &nbsp;{{ user.country }}
              </div>
            </div>

            <div class="ml-3 align-self-center">
              <router-link :to="{ name: 'Profile' }" class="text-white">
                <i class="icon-cog3"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- /user menu -->

      <!-- Main navigation -->
      <div class="card card-sidebar-mobile">
        <ul class="nav nav-sidebar" data-nav-type="accordion">
          <!-- order action menus -->
          <span v-if="userType != userTypes.writer">
            <li
              v-for="(item, index) in actionMenuItems"
              :key="index + 'bmi'"
              class="nav-item shadow"
              :class="showSidebar ? 'px-sm-2 ' : 'px-0 py-1'"
            >
              <router-link
                :to="{ name: item.routeName }"
                class="nav-link bg-primary"
                :class="showSidebar ? 'py-1 rounded' : ' '"
              >
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <!-- Main -->
          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs line-height-xs">Main</div>
            <i class="icon-menu" title="Main"></i>
          </li>

          <span>
            <li
              v-for="(item, index) in baseMenuItems"
              :key="index + 'bmi'"
              class="nav-item py-0"
            >
              <router-link :to="{ name: item.routeName }" class="nav-link">
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <!-- base menu -->

          <!-- Main -->
          <!-- <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs line-height-xs">
              Mailing {{userType == userTypes.admin || userType == userTypes.editor}}
            </div>
            <i class="icon-menu" title="Main"></i>
          </li> -->

          <li
            class="nav-item py-0"
            v-show="userType == userTypes.admin || userType == userTypes.editor"
          >
            <router-link :to="{ name: 'NewEmail' }" class="nav-link">
              <div class="d-inline-block icons-sm mr-1">
                <i class="icon-paperplane"></i>
              </div>
              <div class="d-block w-100 text-left">
                <span class="text-left">Bulk Messages</span>
              </div>
            </router-link>
          </li>
          
          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs line-height-xs">Orders</div>
            <i class="icon-menu" title="Main"></i>
          </li>

          <li class="nav-item py-0">
            <router-link :to="{ name: 'Bids' }" class="nav-link">
              <div class="d-inline-block icons-sm mr-1">
                <i class="icon-add-to-list"></i>
              </div>
              <div class="d-block w-100 text-left">
                <span class="badge badge-pill float-right bg-info">{{
                  bidsCount || 0
                }}</span>
                <span class="text-left">Bids</span>
              </div>
            </router-link>
          </li>

          <!-- order menu -->
          <span class="is-conditional-menu">
            <li
              v-for="(item, index) in sidebarMenu"
              :key="index + 'om'"
              class="nav-item py-0"
            >
              <router-link
                @click="getSidebarMenu"
                :to="{
                  name: 'OrderListing',
                  params: {
                    status: item.value,
                    canbid: item.can_bid ? 'biding' : 'b',
                  },
                }"
                class="nav-link"
              >
                <div class="d-inline-block icons-sm mr-1">
                  <i class="icon-pencil"></i>
                </div>
                <div class="d-block w-100 text-left">
                  <span
                    class="badge badge-pill float-right"
                    :class="`bg-${item.color || 'dark'}`"
                    >{{ item.no || 0 }}</span
                  >
                  <span class="text-left">{{ item.name }}</span>
                </div>
              </router-link>
            </li>
          </span>

          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs line-height-xs">Menu</div>
            <i class="icon-menu" title="Main"></i>
          </li>

          <span
            class="is-conditional-menu"
            v-show="userType == userTypes.admin"
          >
            <li
              v-for="(item, index) in adminMenu"
              :key="index + 'bmi'"
              class="nav-item py-0"
            >
              <router-link :to="{ name: item.routeName }" class="nav-link">
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <span
            class="is-conditional-menu"
            v-show="userType && userType == userTypes.client"
          >
            <li
              v-for="(item, index) in clientMenu"
              :key="index + 'bmi'"
              class="nav-item py-0"
            >
              <router-link :to="{ name: item.routeName }" class="nav-link">
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <span
            class="is-conditional-menu"
            v-show="userType && userType == userTypes.editor"
          >
            <li
              v-for="(item, index) in editorMenu"
              :key="index + 'bmi'"
              class="nav-item py-0"
            >
              <router-link :to="{ name: item.routeName }" class="nav-link">
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <span
            class="is-conditional-menu"
            v-show="userType && userType == userTypes.writer"
          >
            <li
              v-for="(item, index) in writerMenu"
              :key="index + 'bmi'"
              class="nav-item py-0"
            >
              <router-link :to="{ name: item.routeName }" class="nav-link">
                <i :class="item.icon"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </li>
          </span>

          <li class="nav-item" style="cursor: pointer">
            <a @click="doLogout" class="nav-link">
              <i class="icon-lock"></i>
              <span>Logout</span>
            </a>
          </li>

          <!-- setups -->
  

          <li
            v-show="userType == userTypes.admin || userType == userTypes.client"
            class="nav-item nav-item-submenu"
            :class="toggle_setups ? 'nav-item-open' : ''"
          >
            <a
              href="#"
              class="nav-link"
              @click="toggle_setups = !toggle_setups"
            >
              <i class="icon-cog3"></i>
              <span>Setups</span>
            </a>
            <ul
              class="nav nav-group-sub"
              :class="toggle_setups ? 'd-block' : 'd-none'"
              data-submenu-title="Icons"
              style="display: none"
            >
              <li
                v-for="(item, index) in setupsMenu"
                :key="index + 'setum'"
                class="nav-item"
              >
                <router-link :to="{ name: item.routeName }" class="nav-link">
                  <i :class="item.icon"></i>
                  <span>{{ item.text || "" }}</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- setups -->
          <!-- /main -->
        </ul>
      </div>
      <!-- /main navigation -->
    </div>
    <!-- /sidebar content -->
  </div>
  <!-- /main sidebar -->
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import {
  actionMenuItems,
  baseMenuItems,
  writerMenu,
  clientMenu,
  editorMenu,
  adminMenu,
  setupsMenu,
} from "./sidebar.model";

export default {
  name: "Sidebar",

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("sidebar", ["showSidebar", "sidebarMenu", "bidsCount"]),
  },
  data() {
    return {
      actionMenuItems: actionMenuItems,
      baseMenuItems: baseMenuItems,
      adminMenu: adminMenu,
      clientMenu: clientMenu,
      writerMenu: writerMenu,
      editorMenu: editorMenu,
      setupsMenu: setupsMenu,
      toggle_setups: false,
    };
  },
 
  mounted() {
    this.getSidebarMenu();
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),
    ...mapMutations("sidebar", ["_toggleSidebarMobile"]),
  },
};
</script>


<style lang="scss" scoped>
.sidebar-xs {
  width: 100% !important;
}
.nav-item {
  max-height: 40px !important;
  min-height: 40px !important;
  height: 40px !important;
}
.nav-link {
  padding: 8px 10px !important;
}
.sidebar {
  .nav-link {
    padding: 0.5rem 0.8rem;
  }
}
.modal-backdrop {
  z-index: 1 !important;
}

.sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link {
  padding-left: 0;
  padding-right: 0;
  max-height: 40px !important;
}

.is-conditional-menu * {
  transition: 0.3s all ease-in-out;
}

.nav-sidebar .nav-item > .nav-link.active {
  border-radius: 0px 8px 8px 0px;
  margin-right: 4px;
  background: rgba(184, 193, 198, 0.49) !important;
  transition: 0.3s ease-in-out;
}
.nav-link.active.just-active {
  max-height: 34px !important;
}
.sidebar-xs {
  .nav-item-header {
    display: none;
  }
}

.nav-sidebar .nav-link i {
  margin-right: 1.25rem;
  margin-top: 0rem !important;
  margin-bottom: 0.12502rem;
  top: 0;
}

.sidebar-xs .sidebar-main {
  // width: 2.8rem;
  width: 16.875rem;

  a.nav-link.bg-primary.py-1.rounded {
    border-radius: 0px 8px 8px 0px !important;
    max-height: 20px;
    margin-right: 5px;
  }
}
// .sidebar-xs .sidebar-main:hover {
//   width: 16.875rem;
//   -webkit-animation: slideInOut 0.2s;
//   -moz-animation: slideInOut 0.2s;
//   -o-animation: slideInOut 0.2s;
//   animation: slideInOut 0.2s;
// }

@keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}

@-o-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}
@-moz-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}
@-webkit-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}

.nav-link.bg-primary {
  border-radius: 0px 8px 8px 0px;
}

.nav.nav-group-sub.d-block .nav-item .nav-link {
  padding-left: 22px !important;
}
.nav-item-submenu.nav-item-open a {
  border-radius: 0 8px 8px 0px !important;
}
</style>