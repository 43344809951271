<template>
  <!-- Main navbar -->
  <div class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="navbar-brand mr-0">
      <a href="/" class="d-inline-block">
        <img src="/logo.png" alt="proxim" />
      </a>
    </div>

    <div class="d-md-none">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-mobile"
      >
        <i class="icon-tree5"></i>
      </button>
      <button
        @click="_toggleSidebarMobile"
        class="navbar-toggler sidebar-mobile-main-toggle"
        type="button"
      >
        <i class="is-icon-toggle" :class="'icon-paragraph-justify3'"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbar-mobile">
      
      <ul class="navbar-nav is-search">
        <form @submit.prevent="doSearch">
          <v-text-field
            placeholder="Search.."
            dark
            v-model="search"
            hide-details
            outlined
            dense
          >
            <v-icon @click="doSearch" slot="append" color="green"
              >mdi-magnify</v-icon
            >
          </v-text-field>
        </form>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown dropdown-notifications">
          <a href="#" class="navbar-nav-link" data-toggle="dropdown">
            <i class="icon-bell2"></i>
            <span class="d-md-none ml-2">Notifications</span>
            <span class="badge badge-mark border-white ml-auto ml-md-0"></span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <span v-if="notifications && notifications.length > 0">
              <router-link v-for="(notification, index) in notifications" :key="index" :to="{ name: 'Profile' }" class="dropdown-item"
                >
                <i class="icon-bell2"></i>
                {{notification}}
              </router-link>
            </span>
          </div>
        </li>

        <li class="nav-item dropdown dropdown-user">
          <a
            href="#"
            class="navbar-nav-link d-flex align-items-center dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src="../assets/global_assets/images/image.png"
              class="rounded-circle mr-2"
              height="18"
              alt=""
            />
            <span>
              {{ user.first_name || user.last_name }}
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ name: 'Profile' }" class="dropdown-item"
              ><i class="icon-user-plus"></i> My profile</router-link
            >
            <router-link :to="{ name: 'Balance' }" class="dropdown-item"
              ><i class="icon-coins"></i> My balance</router-link
            >
            <router-link :to="{ name: 'Payments' }" class="dropdown-item"
              ><i class="icon-comment-discussion"></i> Payments
              <!-- <span class="badge badge-pill bg-blue ml-auto">58</span> -->
            </router-link>
            <div class="dropdown-divider"></div>
           
            <a @click="doLogout" class="dropdown-item"
              ><i class="icon-switch2"></i> Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- /main navbar -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Alerts from "../../mixins/alerts";
export default {
  name: "Navbar",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("sidebar", ["showSidebarMobile"]),
  },
  data() {
    return {
      search: "",
      notifications: [],
    };
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapMutations("sidebar", ["_toggleSidebar", "_toggleSidebarMobile"]),
    doSearch() {
      document.location.href = `/home/search?search=${this.search}`;
    },

    getNotifications() {
      this.$req
        .apiGet("/Notifications/Dummy")
        .then(({data}) => {
          this.notifications = data.Data;
        })
        .catch((err) => console.log("noti error =====>", err));
    },
  },
  mounted() {
    this.search = this.$route.query.search;
    this.getNotifications();
  },
  mixins: [Alerts],
};
</script>

<style lang="scss" scoped>
.is-icon-toggle {
  transition: 0.3s all ease-in-out;
}
.is-search {
  width: 100%;
  form {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .is-search {
    width: 100% !important;
    margin-bottom: 20px;
    margin-top: 20px;
    form {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .is-search {
    width: 100% !important;
    margin-bottom: 20px;
    margin-top: 20px;
    form {
      width: 100% !important;
      // margin-top: 12px;
    }
  }
}
</style>
