import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../Orders.vue"),
    children: [
      {
        path: "",
        redirect: { name: "Login" }
      },
      {
        path: "place-order",
        name: "PlaceOrder",
        component: () => import("../views/pages/PlaceOrder.vue"),
        meta: {
          // guest: true,
          title: "Place Order",
        },
      },
      {
        path: "system/payments",
        name: "SystemPayments",
        component: () => import("../views/pages/SystemPayments.vue"),
        meta: {
          title: "System Payments",
          requireAuth: true,
        },
      },
      {
        path: "login",
        name: "Login",
        component: () => import("../views/auth/Login.vue"),
        meta: {
          guest: true,
          title: "Login to your account",
        },
      },

      {
        path: "register",
        name: "Register",
        component: () => import("../views/auth/Register.vue"),
        meta: {
          guest: true,
          title: "Create an account",
        },
      },

      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () => import("../views/auth/ForgotPassword.vue"),
        meta: {
          guest: true,
          title: "Forgot Password",
        },
      },

      {
        path: "orders/reset-password",
        name: "PasswordReset",
        component: () => import("../views/auth/PasswordReset.vue"),
        meta: {
          guest: true,
          title: "Forgot password?",
        },
      },

      {
        path: "reset-password",
        name: "PasswordReset",
        component: () => import("../views/auth/PasswordReset.vue"),
        meta: {
          guest: true,
          title: "Forgot password?",
        },
      },

      {
        path: "home",
        name: "Home",
        component: Home,
        children: [
          {
            path: "",
            name: "Dashboard",
            component: () => import("../views/pages/Dashboard.vue"),
            meta: {
              title: "Dashboard",
              requireAuth: true,
            },
          },

          {
            path: "search",
            name: "Search",
            component: () => import("../views/pages/Search.vue"),
            meta: {
              title: "Search",
              requireAuth: true,
              client: true,
            },
          },

          {
            path: "profile",
            name: "Profile",
            component: () => import("../views/pages/Profile.vue"),
            meta: {
              title: "Profile",
              requireAuth: true,
            },
          },

          {
            path: "client-profile/:id",
            name: "ClientProfile",
            component: () => import("../views/pages/ClientProfile.vue"),
            meta: {
              title: "Client Profile",
              requireAuth: true,
              client: true,
            },
          },
          {
            path: "writer-profile/:id",
            name: "WriterProfile",
            component: () => import("../views/pages/WriterProfile.vue"),
            meta: {
              title: "Writer Profile",
              requireAuth: true,
            },
          },
          {
            path: "messages",
            name: "Messages",
            component: () => import("../views/pages/Messages.vue"),
            meta: {
              title: "Messages",
              requireAuth: true,
            },
          },


          {
            path: 'mailing',
            name: "Mailing",
            component: () => import("../views/pages/Mailing.vue"),
            meta: {
              title: "Mailing",
              requireAuth: true,
            },
          },
          {
            path: "mailing/send",
            name: "NewEmail",
            component: () => import("../views/pages/SendMail.vue"),
            meta: {
              title: "Send Mail",
            },
          },

          {
            path: "my-writers",
            name: "MyWriters",
            component: () => import("../views/pages/MyWriters.vue"),
            meta: {
              title: "My Writers",
              requireAuth: true,
            },
          },
          {
            path: "wallet",
            name: "Wallet",
            component: () => import("../views/pages/Wallet.vue"),
            meta: {
              title: "Wallet",
              requireAuth: true,
            },
          },

          {
            path: "tips",
            name: "Tips",
            component: () => import("../views/pages/Tips.vue"),
            meta: {
              title: "Tips",
              requireAuth: true,
            },
          },
          {
            path: "earn-from-refferals",
            name: "EarnFromRefferals",
            component: () => import("../views/pages/EarnFromRefferals.vue"),
            meta: {
              title: "Earn From Refferals",
              requireAuth: true,
            },
          },

          {
            path: "my-discounts",
            name: "MyDiscounts",
            component: () => import("../views/pages/MyDiscounts.vue"),
            meta: {
              title: "My Discounts",
              requireAuth: true,
            },
          },

          {
            path: "orders/:canbid?/:status",
            name: "OrderListing",
            component: () => import("../views/pages/OrderListing.vue"),
            meta: {
              title: "Order Listing",
              requireAuth: true,
            }
          },
          {
            path: "order/:id",
            name: "Order",
            component: () => import("../views/pages/Order.vue"),
            meta: {
              title: "Order",
              required: true,
              requireAuth: true,
            }
          },
          {
            path: "order/edit/:id",
            name: "edit",
            component: () => import("../views/pages/EditOrder.vue"),
            meta: {
              title: "EditOrder",
              required: true,
              requireAuth: true,
            }
          },
          {
            path: "checkout/:id",
            name: "payment",
            component: () => import("../views/pages/Checkout.vue"),
            meta: {
              title: "Checkout",
              required: true,
              requireAuth: true,
            }
          },

          // admin menu
          {
            path: "clients",
            name: "Clients",
            component: () => import("../views/pages/Clients.vue"),
            meta: {
              title: "Clients",
              requireAuth: true,
              client: true,
            }
          },
          {
            path: "system-clients",
            name: "SystemClients",
            component: () => import("../views/pages/SystemClients.vue"),
            meta: {
              title: "SystemClients",
              requireAuth: true,
              admin: true,
            }
          },

          {
            path: "system-clients/:id",
            name: "ViewSystemClient",
            component: () => import("../views/pages/ViewSystemClient.vue"),
            meta: {
              title: "View Client",
              requireAuth: true,
              admin: true,
            }
          },

          {
            path: "writers",
            name: "Writers",
            component: () => import("../views/pages/Writers.vue"),
            meta: {
              title: "Writers",
              requireAuth: true,
              editor: true,
            }
          },



          {
            path: "editors",
            name: "Editors",
            component: () => import("../views/pages/Editors.vue"),
            meta: {
              title: "Editors",
              requireAuth: true,
              admin: true,
              client: true,
            }
          },



          {
            path: "sites",
            name: "Sites",
            component: () => import("../views/pages/Sites.vue"),
            meta: {
              title: "Sites",
              requireAuth: true,
              client: true
            },
          },

          {
            path: "sites/edit/:id",
            name: "EditSite",
            component: () => import("../views/pages/EditSite.vue"),
            meta: {
              title: "Edit Site",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "websites",
            name: "SystemUserSites",
            component: () => import("../views/pages/SystemUserSites.vue"),
            meta: {
              title: "Websites",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "pending-payments",
            name: "Payments",
            component: () => import("../views/pages/Payments.vue"),
            meta: {
              title: "Pending Payments",
              requireAuth: true,
            },
          },
          {
            path: 'pending-payments/:id',
            name: "ViewWriterPending",
            component: () => import("../views/pages/ViewWriterPending.vue"),
            meta: {
              title: "Pending Writer Payments",
              requireAuth: true,
            }
          },


          {
            path: "balance-payments",
            name: "Balance",
            component: () => import("../views/pages/Balances.vue"),
            meta: {
              title: "Balance Payments",
              requireAuth: true,
            }
          },

          {
            path: "discounts",
            name: "Discounts",
            component: () => import("../views/pages/Discounts.vue"),
            meta: {
              title: "Discounts",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "fines",
            name: "Fines",
            component: () => import("../views/pages/Fines.vue"),
            meta: {
              title: "Fines",
              requireAuth: true,
            }
          },

          {
            path: "bonus",
            name: "Bonus",
            component: () => import("../views/pages/Bonus.vue"),
            meta: {
              title: "Bonus",
              requireAuth: true,
            }
          },

          {
            path: "editor-payments",
            name: "EditorPayments",
            component: () => import("../views/pages/EditorPayments.vue"),
            meta: {
              title: "EditorPayments",
              requireAuth: true,
              client: true
            }
          },

          // setups
          {
            path: "writer-levels",
            name: "WriterLevels",
            component: () => import("../views/pages/WriterLevels.vue"),
            meta: {
              title: "WriterLevels",
              requireAuth: true,
              editor: true
            }
          },

          // setups
          {
            path: "writer-preferences",
            name: "WriterPreferences",
            component: () => import("../views/pages/WriterPreferences.vue"),
            meta: {
              title: "WriterPreferences",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "additional-services",
            name: "AdditionalServices",
            component: () => import("../views/pages/AdditionalServices.vue"),
            meta: {
              title: "AdditionalServices",
              requireAuth: true,
              client: true
            }
          },


          {
            path: "connections",
            name: "Connections",
            component: () => import("../views/pages/Connections.vue"),
            meta: {
              title: "Connections",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "file-types",
            name: "FileTypes",
            component: () => import("../views/pages/FileTypes.vue"),
            meta: {
              title: "FileTypes",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "styles",
            name: "Styles",
            component: () => import("../views/pages/Styles.vue"),
            meta: {
              title: "Styles",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "academic-levels",
            name: "AcademicLevels",
            component: () => import("../views/pages/AcademicLevels.vue"),
            meta: {
              title: "AcademicLevels",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "document-types",
            name: "DocumentTypes",
            component: () => import("../views/pages/DocumentTypes.vue"),
            meta: {
              title: "DocumentTypes",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "subject-areas",
            name: "SubjectAreas",
            component: () => import("../views/pages/SubjectAreas.vue"),
            meta: {
              title: "SubjectAreas",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "language-styles",
            name: "LanguageStyles",
            component: () => import("../views/pages/LanguageStyles.vue"),
            meta: {
              title: "LanguageStyles",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "spacing",
            name: "Spacing",
            component: () => import("../views/pages/Spacing.vue"),
            meta: {
              title: "Spacing",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "bids",
            name: "Bids",
            component: () => import("../views/pages/Bids.vue"),
            meta: {
              title: "Bids",
              requireAuth: true,
            }
          },

          {
            path: "urgency",
            name: "Urgency",
            component: () => import("../views/pages/Urgency.vue"),
            meta: {
              title: "Urgency",
              requireAuth: true,
              client: true
            }
          },

          {
            path: "404",
            name: "Home404",
            meta: {
              requireAuth: true,
              title: "Not Found",
            },
            component: () => import("../views/pages/Home404.vue"),
          },
        ],
      },

      // errors

      {
        path: "404",
        name: "404",
        meta: {
          requireAuth: false,
          title: "Not Found",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/pages/404.vue"),
      },

      {
        path: "503",
        name: "503",
        meta: {
          requireAuth: false,
          title: "Unauthorized",
        },
        component: () => import("../views/pages/503.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: "just-active",
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 };
  },
});

// validate token
function parseJwt() {
  try {
    let token = localStorage.getItem("token").replace("Bearer ", "");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      Buffer.from(base64, "base64").toString("ascii")
    );

    // 
    return JSON.parse(jsonPayload);
  } catch (e) {
    return false;
  }
}

import store from "../store/index";

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next({ path: "/404" });
  }

  var token = localStorage.getItem("token");
  const jwtPayload = parseJwt();



  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (token && jwtPayload && jwtPayload.expiry > Date.now() / 1000) {
      // if token exists and jwtPayload.exp is not expired
      next();
      // role based middlewares
      /*      admin: 0,
              client: 4,
              writer: 1,
              editor: 3,
       */
      var userType = jwtPayload.user.type;
      if (to.matched.some((record) => record.meta.admin)) {
        if (userType == "0") {
          next();
        } else next({ path: "/503" });
      }

      else if (to.matched.some((record) => record.meta.client)) {
        if (userType == "0" || userType == 4) {
          next();
        } else next({ path: "/503" });
      }

      else if (to.matched.some((record) => record.meta.editor)) {
        if (userType == "0" || userType == 4 || userType == 3) {
          next();
        } else next({ path: "/503" });
      }

      // role based middlewares

    } else {
      // token is expired or invalid
      localStorage.removeItem("token");
      next({ path: "/login", params: { nextUrl: to.fullPath } });
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    // route doesnt require auth
    if (token && jwtPayload && jwtPayload.expiry > Date.now() / 1000) {
      // if token exists and is valid
      next({ path: "/home" });
    } else {
      next();
    }
  } else next();

  to.matched.some((record) => {
    store.dispatch("auth/doGetAuthUser");
    store.dispatch("setups/getSiteSettings");
    document.title = `${process.env.VUE_APP_NAME} - ${record.meta.title || ""
      }`;
    store.dispatch("auth/ ");
    document.body.classList.remove("sidebar-mobile-main")
  });
});

export default router;
