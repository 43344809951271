<template>
    <div class="overlay-loading">
        <img :src="loading_spinner" width="60px" alt="">
    </div>
</template>


<script>
import loading_spinner from "../assets/global_assets/images/loading.gif";
export default {
    name: "BaseOverlay",
    data() {
      return {
        loading_spinner: loading_spinner
      }
    }
}
</script>

<style lang="scss" scoped>
.overlay-loading {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
</style>
