import http from '../modules/http.index'
import Form from 'vform'

const state = () => ({
    sendMessageUrl: `${process.env.VUE_APP_API_BASE_URL}/Orders/Messages/`,
    orderMessages: [],
    allMessages: [],
    loading: false,
    messageForm: new Form({
        receiver_id: "",
        message: ""
    })
})
const getters = {}


const mutations = {
    setMessages: (state, payload) => state.orderMessages = payload,
    setAllMessages: (state, payload) => state.allMessages = payload
}


const actions = {
    async _getAllMessages(ctx) {
        return new Promise((resolve, reject) => {
            ctx.state.loading = true;
            http
                .get(`/Users/Messages/`) // payload = order_id
                .then((res) => {
                    console.log("messages", res);
                    if (res.status === 200) {
                        ctx.commit("setAllMessages", res.data.Data)
                        resolve(res)
                    }
                    else reject(res)
                })
                .catch((err) => reject(err))
                .finally(() => ctx.state.loading = false)
        })
    },
     
    async _getMessages(ctx, payload) {
      return new Promise((resolve, reject) => {
        http
            .get(`/Orders/Messages/${payload}`) // payload = order_id
            .then((res) => {
                if (res.status === 200) {
                    ctx.commit("setMessages", res.data.Data)
                    resolve(res)
                }
                else reject(res)
            })
            .catch((err) => reject(err))
      })  
    },
    async _sendMessage(ctx, payload) {
        return new Promise((resolve, reject) => {
            payload.messageForm
                .post(ctx.state.sendMessageUrl + payload.order_id)
                .then((res) => {
                    if (res.status === 200) {
                        ctx.dispatch('_getMessages', payload.order_id) // refresh messages after sending a message
                        resolve(res)
                    } 
                    else reject(res) 
                })
                .catch((err) => reject(err))
        })
    }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}