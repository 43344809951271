<template>
  <div class="navbar navbar-expand-md navbar-dark">
    <!-- Main navbar -->
    <div class="container p-0">
      <div class="navbar-brand">
        <a href="/orders" class="d-inline-block">
          <img src="/logo.png" alt="proxim" />
        </a>
      </div>

      <div class="d-md-none">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i class="icon-paragraph-justify3"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="navbar-nav-link" :to="{ name: 'PlaceOrder' }"
              >Place Order</router-link
            >
          </li>

          <!-- <li class="nav-item">
            <router-link class="navbar-nav-link" :to="{ name: 'BulkOrder' }"
              >Bulk Order</router-link
            >
          </li> -->
          <li class="nav-item" v-if="isAuth">
            <router-link class="navbar-nav-link" :to="{ name: 'Dashboard' }"
              >Home</router-link
            >
          </li>
          <span v-if="isAuth">
            <li class="nav-item py-1">
              <button class="btn btn-secondary" @click="doLogout">
                Logout
              </button>
            </li>
          </span>
          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link" :to="{ name: 'Login' }"
              >Login</router-link
            >
          </li>

          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link" :to="{ name: 'Register' }"
              >Register</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- /main navbar -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Nav",
  computed: {
    ...mapState("auth", ["isAuthenticated", "user"]),
    isAuth() {
      if (
        this.isAuthenticated &&
        this.user &&
        Object.keys(this.user).length > 0
      ) {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
  },
};
</script>
